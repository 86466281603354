<template>
    <highcharts
    ref="chart"
    :options="options"
    :highcharts="hcInstance"
    :callback="
      (event) => {
        this.element = event;
        this.$emit('elementLoaded', event);
      }
    "
  />
</template>

<script>

import Highcharts from 'highcharts'
import HeatMap from "highcharts/modules/heatmap";

HeatMap(Highcharts)

import store from '@/store'
import Vue from "vue";

import
{
  BRow,
  BCol
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,    
  },

  directives: {
  },

  created()
  {

  },

  methods: {

    getRef()
    {

      return this.$refs.chart

    },

    updateElement()
    {

    },


  },

  watch: {
    // whenever question changes, this function will run
    isVerticalMenuCollapsed(newValue, oldValue)
    {
      setTimeout(() => {
        this.$refs.chart.chart.reflow()
      }, 1000)


    }
  },


  mounted()
  {

    



  },

  beforeDestroy()
  {
    //console.log("beforeDestroy 2");
  },

  computed: {
    isVerticalMenuCollapsed()
    {
      return store.state.verticalMenu.isVerticalMenuCollapsed
    }
  },

  data()
  {
    return {
      element: null,
      hcInstance: Highcharts
    };
  },

  props: {
    load: null,
    options: {
      type: Object,
      required: true,
    },
    wrapperProps: {
      type: Object,
      default: {}
    }
  },
};
</script>

<style lang="scss">
@import "~highcharts/css/stocktools/gui.css";
@import "~highcharts/css/annotations/popup.css";
</style>
